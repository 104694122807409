import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { showConnectInstagramSelector } from "state/complexSelectors";
import Divider from "ui/common/divider/Divider";
import InstagramListItem from "ui/common/dropdownMenu/menuList/components/InstagramListItem";

interface ConditionalUserItemsProps {
  withDivider?: boolean;
}

const ConditionalUserItems: React.FC<ConditionalUserItemsProps> = ({
  withDivider = false,
}) => {
  const showInstagramButton = useSelector(
    showConnectInstagramSelector,
    shallowEqual
  );

  if (!showInstagramButton) {
    return null;
  }

  return (
    <>
      {showInstagramButton && <InstagramListItem />}
      {withDivider && <Divider />}
    </>
  );
};

export default memo(ConditionalUserItems);
