import React, { useCallback, useRef, memo } from "react";
import PropTypes from "prop-types";
import { useLightbox } from "ui/common/lightbox/LightboxContext";

import styles from "./PicturePost.scss";
import { useLazyBackgroundImageImmutable } from "ui/hooks/useLazyBackgroundImage";
import classnames from "classnames";

export const PicturePost = ({ imgSrc, className, children, ...rest }) => {
  const ref = useRef();
  useLazyBackgroundImageImmutable(ref);
  return (
    <div
      ref={ref}
      className={classnames(className, styles.root)}
      data-lazybg={imgSrc}
      {...rest}
    >
      {children}
    </div>
  );
};

export const LightboxPicturePost = ({ allPhotos, index, ...rest }) => {
  const openLightbox = useLightbox();
  const onClick = useCallback(() => {
    openLightbox({ photos: allPhotos, index });
  }, [allPhotos, index]);
  return <PicturePost {...rest} onClick={onClick} />;
};

PicturePost.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

LightboxPicturePost.propTypes = {
  allPhotos: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  height: PropTypes.string,
};

export default memo(PicturePost);
