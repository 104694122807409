import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { TooltipPosition } from "src/enums";
import { Nullable } from "src/types/common";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import styles from "./Tooltip.scss";

const TOOLTIP_DELAY_MS_DEFAULT = 400;

interface TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  contentClassName?: string;
  delay?: number;
  direction?: "bottom" | "left" | "right" | "top";
  disabled?: boolean;
  wrapperClassName?: string;
}

/**
 * `Tooltip` component.
 * A simple text popup tip.
 *
 * Props:
 * - `children`: The target element over which the tooltip will appear. This can be any ReactNode.
 * - `content`: (Optional) The content displayed within the tooltip. This can be any ReactNode.
 * - `contentClassName`: (Optional) A CSS class name for styling the tooltip content specifically. This allows for custom styles to be applied directly to the content area of the tooltip.
 * - `delay`: (Optional) The delay in milliseconds before the tooltip appears after a mouse hover event. Defaults to 400ms.
 * - `direction`: (Optional) Specifies the preferred direction of the tooltip relative to the child element. It supports "bottom", "left", "right", and "top". Defaults to "top".
 * - `disabled`: (Optional) A boolean indicating if the tooltip component is interactive. When true, the component is non-interactive.
 * - `wrapperClassName`: (Optional) A CSS class name for styling the overall container of the tooltip, including the child and the tooltip itself.
 *
 *
 * @returns JSX.Element - The rendered tooltip component.
 *
 * @example
 * <Tooltip
 *   content="Tooltip text here"
 *   direction={TooltipPosition.TOP}
 *   delay={500}
 * >
 *   <button>Hover over me</button>
 * </Tooltip>
 */

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  delay = TOOLTIP_DELAY_MS_DEFAULT,
  direction = TooltipPosition.TOP,
  contentClassName,
  wrapperClassName,
  disabled = false,
}) => {
  const timeout = useRef<Nullable<number>>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});

  const showTip = useCallback(() => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(() => {
      setActive(true);
    }, delay);
  }, [delay]);

  const hideTip = useCallback(() => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }

    setActive(false);
  }, []);

  useEffect(() => {
    if (!active || !childRef.current || !tooltipRef.current) {
      return;
    }
    const margin = 8;
    let top;
    let left;

    switch (direction) {
      case "top":
        top = -(tooltipRef.current.offsetHeight + margin * 2);
        left = "50%";
        break;
      case "bottom":
        top = childRef.current.offsetHeight + margin * 2;
        left = "50%";
        break;
      case "left":
        top = "50%";
        left = -(tooltipRef.current.offsetWidth + margin);
        break;
      case "right":
        top = "50%";
        left = childRef.current.offsetWidth + margin;
        break;
      default:
        break;
    }

    setTooltipStyle({
      position: "absolute",
      top: top === "50%" ? top : `${top}px`,
      left: left === "50%" ? left : `${left}px`,
      transform: `translate(${left === "50%" ? "-50%" : "0"}, ${
        top === "50%" ? "-50%" : "0"
      })`,
    });

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [active, direction, children]);

  return (
    <div
      className={classNames(styles.root, wrapperClassName)}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      ref={childRef}
    >
      {children}

      {active && !disabled && (
        <div
          role="tooltip"
          className={classNames(
            styles.tip,
            styles[direction],
            contentClassName
          )}
          style={tooltipStyle}
          ref={tooltipRef}
        >
          <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH4}>{content}</Typography>
        </div>
      )}
    </div>
  );
};

Tooltip.displayName = "Tooltip";
