import React, { FC, useCallback } from "react";
import { LanguageListOption } from "ui/common/dropdownMenu/menuList/components/languageSwitcher/types";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { ReactComponent as IconChecked } from "img/user_menu_icons/check-mark-pink.svg";
import styles from "ui/common/dropdownMenu/menuList/components/languageSwitcher/LanguageMenu.scss";

interface LanguageMenuItemProps {
  isSelected: boolean;
  onClick: (option: LanguageListOption) => void;
  option: LanguageListOption;
}

const LanguageMenuItem: FC<LanguageMenuItemProps> = ({
  option,
  isSelected,
  onClick,
}) => {
  const onClickHandler = useCallback(() => onClick(option), [option]);

  return (
    <div
      onClick={onClickHandler}
      data-value={option.label}
      className={styles.option}
      data-testid={`language-option-${option.value}`}
    >
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH2}
        className={styles.optionLabel}
      >
        {option.label}
      </Typography>

      {isSelected && <IconChecked className={styles.checkedIcon} />}
    </div>
  );
};

export default LanguageMenuItem;
