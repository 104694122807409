import React, { FC, memo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import CardsCarousel from "src/ui/common/nftCards/components/CardsCarousel";
import OwnedCards from "src/ui/common/nftCards/components/OwnedCards";
import CardsSkeleton from "ui/common/nftCards/components/CardsSkeleton";
import NoContent from "ui/common/noContent/NoContent";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useLoadLotsByAccountId from "ui/hooks/useLoadLotsByAccountId";
import { linkToNFTAuction } from "ui/navigation/links";
import { ReactComponent as LinkIcon } from "img/nft/link-arrow.svg";
import styles from "./NftCards.scss";

interface NftCardsProps {
  accountId: string;
  className?: string;
  dataTestId?: string;
  forceMobileLayout?: boolean;
  isLinkVisible?: boolean;
  isMiniProfile?: boolean;
  noContentClassName?: string;
  onShader?: boolean;
  screenName?: string;
  titleClassName?: string;
  uiComponent?: string;
  withoutEmptyState?: boolean;
}

const NftCards: FC<NftCardsProps> = ({
  className,
  screenName,
  uiComponent,
  accountId,
  isLinkVisible = false,
  noContentClassName,
  titleClassName,
  forceMobileLayout,
  onShader = false,
  dataTestId,
  isMiniProfile,
  withoutEmptyState,
}) => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP && !forceMobileLayout;
  const titleTypography = isDesktop
    ? TYPOGRAPHY_TYPE.HEADLINE1
    : TYPOGRAPHY_TYPE.HEADLINE4;
  const { lots, ownedNfts, creatorNfts, isLoading } =
    useLoadLotsByAccountId(accountId);
  const shouldDisplay = !forceMobileLayout || isMiniProfile;
  const isOwnedCardsVisible = shouldDisplay && ownedNfts?.length > 0;
  const isCreatorCardsVisible = shouldDisplay && creatorNfts?.length > 0;
  const isLotsVisible = lots.length > 0;
  const isCreatorOrLotCard = isCreatorCardsVisible || isLotsVisible;

  if (!isOwnedCardsVisible && !isCreatorCardsVisible && !isLotsVisible) {
    return forceMobileLayout && withoutEmptyState ? null : (
      <>
        {isLoading ? (
          <CardsSkeleton noPulse={isMiniProfile} />
        ) : (
          <NoContent className={noContentClassName} type="cards" />
        )}
      </>
    );
  }

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.breakpoint]: !forceMobileLayout,
          [styles.isMiniProfile]: isMiniProfile,
        },
        className
      )}
      data-testid={dataTestId}
    >
      {isCreatorOrLotCard && (
        <Typography
          type={titleTypography}
          className={classnames(styles.title, titleClassName)}
          as="div"
        >
          <FormattedMessage id="tango_cards" defaultMessage="Tango Cards" />
        </Typography>
      )}
      <CardsCarousel
        lots={lots}
        creatorCards={creatorNfts}
        uiComponent={uiComponent}
        screenName={screenName}
        forceMobileLayout={forceMobileLayout}
        accountId={accountId}
        onShader={onShader}
      />
      {isOwnedCardsVisible && (
        <>
          <Typography
            type={titleTypography}
            className={classnames(styles.title, titleClassName, {
              [styles.notFirst]: isCreatorOrLotCard,
            })}
            as="span"
          >
            <FormattedMessage id="owned.cards" defaultMessage="Owned Cards" />
          </Typography>
          <OwnedCards
            cards={ownedNfts}
            screenName={screenName}
            onShader={onShader}
            className={isMiniProfile && styles.isMiniProfileOwnedCards}
          />
        </>
      )}
      {isLinkVisible && (
        <Typography
          type={TYPOGRAPHY_TYPE.HEADLINE6}
          to={{ pathname: linkToNFTAuction, state: { source: "mini_profile" } }}
          className={styles.link}
          data-testid="mini-profile-auction-link"
          as={Link}
        >
          <FormattedMessage id="auction_link" defaultMessage="Go to Auction" />
          <LinkIcon />
        </Typography>
      )}
    </div>
  );
};

export default memo(NftCards);
