import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { RegistrationSource } from "@analytics/enums";
import {
  GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE,
  GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE,
  GetTheAppSource,
} from "@analytics/getTheApp";
import { NEW_SIGN_UP_PROMOTION_TYPE_OTHER } from "enums/newSignUpPromotionType";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { VoidCallback } from "src/types/common";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import messages from "ui/common/intl/sharedMessages";
import { ReactComponent as LogoutIcon } from "img/new-ui/ic-logout-32.svg";

interface LoginProps {
  onClick: VoidCallback;
}

const Login: FC<LoginProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const screenSource = GetTheAppSource.BURGER_MENU;

  const onLoginClick = useCallback(() => {
    dispatch(
      openLoginView({
        registrationSource: RegistrationSource.SELF_PROFILE_ICON,
        newSignUpPromotionType: NEW_SIGN_UP_PROMOTION_TYPE_OTHER,
        title: GET_THE_APP_SOURCE_TO_LOGIN_MODAL_TITLE_TYPE[screenSource],
        screenType: GET_THE_APP_SOURCE_BOTTOM_SCREEN_TYPE[screenSource],
      })
    );
    onClick();
  }, [dispatch, screenSource, onClick]);

  return (
    <DropdownListItem
      titleMessage={messages.login}
      Icon={LogoutIcon}
      onClick={onLoginClick}
      data-testid="login"
    />
  );
};

export default Login;
