import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Nullable } from "src/types/common";
import { AnimationJsonData, MessageEventData } from "src/types/gift";
import giftCacheActionCreators from "state/actionCreators/giftCache";
import { RootState } from "state/delegate";
import { giftsCacheSelectors } from "state/selectors";

const giftWorker = new Worker(
  new URL("src/workers/GiftZipWorker.ts", import.meta.url)
);

const selectGiftAnimation = (state: RootState, url: string) =>
  giftsCacheSelectors.getGiftAnimationByKey(state, url);

export default (url: string) => {
  const [data, setData] = useState<Nullable<AnimationJsonData>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const cachedGiftAnimation = useSelector(
    (state: RootState) => selectGiftAnimation(state, url),
    shallowEqual
  );

  const messageHandler = useCallback(
    (event: MessageEvent<MessageEventData>) => {
      const { jsonData, error } = event.data;
      if (error) {
        console.error(error); // eslint-disable-line no-console
      }

      if (jsonData) {
        setData(jsonData);
        if (!cachedGiftAnimation) {
          dispatch(giftCacheActionCreators.setGiftAnimation({ url, jsonData }));
        }
      }

      setIsLoading(false);
    },
    [setData, setIsLoading]
  );

  useEffect(() => {
    giftWorker?.addEventListener("message", messageHandler, { once: true });

    if (cachedGiftAnimation) {
      setData(cachedGiftAnimation);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      giftWorker?.postMessage({ url });
    }

    return () => {
      giftWorker?.removeEventListener("message", messageHandler);
    };
  }, [messageHandler, giftWorker, url]);

  return { data, isLoading };
};
