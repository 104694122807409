import React, { FC } from "react";
import classnames from "classnames";
import Linkify from "linkify-react";
import { MessageConfig } from "src/ui/scenes/chat/currentConversation/Message";
import { getGroupMessageClassnames } from "src/utils/groupMessageClassnames";
import styles from "./LinkifyText.scss";

const getOptions = (messageConfig?: MessageConfig) => ({
  className: classnames(
    styles.link,
    getGroupMessageClassnames(styles, messageConfig)
  ),
  rel: "noreferrer noopener",
  defaultProtocol: "https",
  target: "_blank",
});

interface LinkifyTextProps {
  messageConfig?: MessageConfig;
}

const LinkifyText: FC<LinkifyTextProps> = ({ children, messageConfig }) => (
  <Linkify options={getOptions(messageConfig)}>{children}</Linkify>
);

export default LinkifyText;
