import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import instaIcon from "img/ic_social_icon_instagram.svg?url";
import instaTitle from "img/ic_instagram_title_white_hor.svg?url";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import styles from "./InstagramPostsHeader.scss";

const InstagramPostsHeader = ({ className, onCloseClick, ...rest }) => (
  <div className={classnames(styles.headerRow, className)} {...rest}>
    <img src={instaIcon} className={styles.logoIcon} />
    <img src={instaTitle} className={styles.titleIcon} />
    <Button
      size={ButtonSize.MEDIUM_32}
      variant={ButtonVariant.ICON_ONLY}
      className={styles.closeButton}
      onClick={onCloseClick}
    >
      <CloseIcon />
    </Button>
  </div>
);

InstagramPostsHeader.propTypes = {
  className: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
};

export default InstagramPostsHeader;
