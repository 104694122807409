import React, { FC, memo, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "state/delegate";
import { profilesCacheSelectors } from "state/selectors";
import { formatDisplayName } from "ui/common/DisplayName";
import EmojiContext from "ui/common/emoji/EmojiContext";

interface UsernameTitleProps {
  accountId: string;
}

const UsernameTitle: FC<UsernameTitleProps> = ({ accountId }) => {
  const intl = useIntl();
  const { removeEmojisIfUnsupported } = useContext(EmojiContext);
  const basicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, accountId),
      [accountId]
    )
  );
  return basicProfile ? (
    <Helmet
      title={formatDisplayName({
        intl,
        basicProfile,
        postProcessName: removeEmojisIfUnsupported,
      })}
    />
  ) : null;
};

export default memo(UsernameTitle);
