import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { REPORT_REASON_MESSAGE_MAX_LENGTH } from "src/constants";
import { removeMessagesRequest } from "src/features/chat/messageRequest/state/asyncAction";
import { emitRealTimeRecommendationsReportEvent } from "src/features/recommendations/helpers/emitRealTimeRecommendationsReportEvent";
import { Nullable, VoidCallback } from "src/types/common";
import { sendReportForProfile, sendReportForStream } from "state/flows/report";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Checkbox from "ui/common/checkbox/Checkbox";
import sharedMessages from "ui/common/intl/sharedMessages";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpoint } from "ui/hooks/useBreakpoint";
import useUiAction from "ui/hooks/useUiAction";
import Input from "ui/modal/modalViews/buyCoins/common/Input";
import styles from "./Report.scss";

const messages = defineMessages({
  streamTitle: {
    id: "modal.report-stream-or-profile.stream-title",
    defaultMessage: "Report this broadcast?",
  },
  profileTitle: {
    id: "modal.report-stream-or-profile.profile-title",
    defaultMessage: "Report this person?",
  },
  messagePlaceholder: {
    id: "modal.report-stream-or-profile.report-reason",
    defaultMessage: "Report reason",
  },
  resultMessage: {
    id: "modal.report-stream-or-profile.result-message",
    defaultMessage: "Thanks for report",
  },
  failedMessage: {
    id: "modal.report-stream-or-profile.failed-message",
    defaultMessage: "Failed to report, please try again later",
  },
});

const reportTypes = [
  {
    reasonType: 1,
    message: sharedMessages.reportSex,
  },
  {
    reasonType: 2,
    message: sharedMessages.reportViolence,
  },
  {
    reasonType: 3,
    message: sharedMessages.reportAbuse,
  },
  {
    reasonType: 4,
    message: sharedMessages.reportSpam,
  },
  {
    reasonType: 5,
    message: sharedMessages.reportOther,
  },
];

const getBiReportReason = (reasonType: number) => {
  switch (reasonType) {
    case 1:
      return "sexual_content";
    case 2:
      return "violent_content";
    case 3:
      return "abusive_content";
    case 4:
      return "spam";
    case 5:
      return "other";
  }
};

const checkFormIsValid = ({
  reasonType,
  reasonMessage,
}: {
  reasonMessage: string;
  reasonType: Nullable<number>;
}) => !!(reasonType === 5 ? reasonMessage : reasonType);

interface ReportProps {
  accountId: string;
  className?: string;
  dismiss: VoidCallback;
  isGuest?: boolean;
  isMobile?: boolean;
  isShaderTheme?: boolean;
  streamId?: string;
}

const Report: React.FC<ReportProps> = ({
  accountId,
  streamId,
  dismiss,
  isGuest = false,
  className = "",
  isMobile,
  isShaderTheme = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const reasons = useMemo(
    () =>
      reportTypes.map(({ message, ...other }) => ({
        ...other,
        label: intl.formatMessage(message),
      })),
    [intl]
  );

  const [reasonType, setReasonType] = useState<Nullable<number>>(null);
  const [reasonMessage, setReasonMessage] = useState<string>("");

  const formIsValid = checkFormIsValid({ reasonType, reasonMessage });

  const store = useRef<{ reasonMessage?: string; reasonType?: string }>({});

  useEffect(() => {
    Object.assign(store.current, {
      reasonType,
      reasonMessage,
    });
  }, [reasonType, reasonMessage]);

  const handleChange = useCallback((event) => {
    setReasonType(+event.target.value);
  }, []);

  const onChangeReasonMessage = useCallback((event) => {
    setReasonMessage(
      event.target.value.substring(0, REPORT_REASON_MESSAGE_MAX_LENGTH)
    );
  }, []);

  const submit = useCallback(() => {
    const { reasonType, reasonMessage } = store.current;
    const onSuccess = () => {
      dismiss();
      enqueueSnackbar(intl.formatMessage(messages.resultMessage), {
        variant: "success",
        anchorOrigin: {
          horizontal: "left",
          vertical: "top",
        },
      });
      dispatch(removeMessagesRequest({ conversationId: accountId }));
    };
    const onError = () => {
      dismiss();
      enqueueSnackbar(intl.formatMessage(messages.failedMessage), {
        variant: "error",
        anchorOrigin: {
          horizontal: "left",
          vertical: "top",
        },
      });
    };

    emitRealTimeRecommendationsReportEvent({
      streamerId: accountId,
      streamId,
      reportReason: reasonType,
    });

    streamId
      ? dispatch(
          sendReportForStream({
            streamId,
            streamerId: accountId,
            reasonType,
            reasonMessage,
            onSuccess,
            onError,
            isMobile,
          })
        )
      : dispatch(
          sendReportForProfile({
            accountId,
            reasonType,
            reasonMessage,
            onSuccess,
            onError,
            isMobile,
          })
        );
  }, [accountId, streamId, dispatch, isMobile, dismiss, enqueueSnackbar, intl]);

  const onSubmit = useUiAction({
    target: "send_report",
    additionalParams: () => ({
      report_reason: getBiReportReason(+(store?.current?.reasonType || 0)),
      peer_id: accountId,
      ...(store.current.reasonType === "5" && {
        report_text: store.current.reasonMessage,
      }),
    }),
    callback: submit,
  });

  if (isGuest) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.shaderTheme]: isShaderTheme,
        },
        styles[breakpoint],
        className
      )}
    >
      <div className={styles.reasonContainer}>
        {reasons.map(({ reasonType: type, label }) => (
          <Checkbox
            className={styles.checkbox}
            key={type}
            onChange={handleChange}
            value={type}
            checked={reasonType === type}
            label={label}
            shader={isShaderTheme}
            radio
          />
        ))}
        {reasonType === 5 && (
          <>
            <Input
              id="reason-message"
              value={reasonMessage}
              onChange={onChangeReasonMessage}
              placeholder={intl.formatMessage(messages.messagePlaceholder)}
              type="text"
              autoComplete="off"
              className={styles.reasonMessage}
              isShaderTheme={isShaderTheme}
              autoFocus
              hideLabel
            />
          </>
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          size={ButtonSize.BIG_48}
          variant={ButtonVariant.PRIMARY}
          className={styles.submit}
          onClick={onSubmit}
          disabled={!formIsValid}
          data-testid="submit"
        >
          <Typography type={TYPOGRAPHY_TYPE.HEADLINE4}>
            <FormattedMessage {...sharedMessages.reportSubmit} />
          </Typography>
        </Button>
        <Button
          size={ButtonSize.BIG_48}
          variant={
            isShaderTheme
              ? ButtonVariant.SECONDARY_ON_SHADER
              : ButtonVariant.SECONDARY
          }
          className={styles.cancel}
          onClick={dismiss}
        >
          <Typography type={TYPOGRAPHY_TYPE.HEADLINE4}>
            <FormattedMessage {...sharedMessages.cancel} />
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default Report;
