import React, { ReactNode } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import { ReactComponent as CloseIcon } from "img/close-rounded.svg";
import sharedMessages from "ui/common/intl/sharedMessages";
import { VoidCallback } from "src/types/common";
import styles from "./ConfirmationView.scss";

interface ConfirmationViewProps {
  onConfirm?: VoidCallback;
  onCancel?: VoidCallback;
  header?: ReactNode;
  footer?: ReactNode;
  showCloseButton?: boolean;
  titleMessage?: MessageDescriptor;
  title?: ReactNode;
  cancelMessage?: MessageDescriptor;
  confirmMessage?: MessageDescriptor;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({
  onConfirm,
  onCancel,
  header,
  footer,
  showCloseButton,
  titleMessage,
  title,
  cancelMessage,
  confirmMessage,
}) => (
  <div className={styles.root}>
    {header}
    <div className={styles.body}>
      <div className={styles.title}>
        {title && title}
        {titleMessage && <FormattedMessage {...titleMessage} />}
      </div>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.PRIMARY}
        className={styles.btn}
        onClick={onConfirm}
      >
        <FormattedMessage {...(confirmMessage || sharedMessages.yes)} />
      </Button>
      <Button
        size={ButtonSize.BIG_48}
        variant={ButtonVariant.SECONDARY}
        className={styles.btn}
        onClick={onCancel}
      >
        <FormattedMessage {...(cancelMessage || sharedMessages.cancel)} />
      </Button>
    </div>
    {showCloseButton && (
      <Button
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.ICON_ONLY}
        onClick={onCancel}
        className={styles.closeButton}
      >
        <CloseIcon />
      </Button>
    )}
    {footer}
  </div>
);

export default ConfirmationView;
