import React, { useEffect, useState, memo } from "react";
import { defineMessage, FormattedMessage } from "react-intl";
import { ReactComponent as ConnectInstagramIcon } from "img/user_menu_icons/connect-instagram.svg";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import DropdownListItem from "./DropdownListItem";
import { useDispatch } from "react-redux";
import { exchangeInstagramTokenForCode } from "state/flows/socialNetworkTokens";
import { usePageVisibilityRef } from "ui/hooks/usePageVisibility";
import {
  usePickImagesFlow,
  useOnConnectInstagramClick,
  useInstagramStorageListener,
  instagramCallbackUrl,
} from "state/flows/utils/instagramHelpers";
import styles from "./common.scss";

const message = defineMessage({
  id: "connect_instagram",
  defaultMessage: "Connect Instagram",
});

const InstagramListItem = () => {
  const isPageVisibleRef = usePageVisibilityRef();
  const [authCode, setAuthCode] = useState("");
  const dispatch = useDispatch();
  const pickImagesFlow = usePickImagesFlow();
  const onConnectInstagramClick = useOnConnectInstagramClick();

  useInstagramStorageListener(setAuthCode);

  useEffect(() => {
    if (!authCode) {
      return;
    }
    dispatch(
      exchangeInstagramTokenForCode({
        code: authCode,
        redirectUri: instagramCallbackUrl,
      })
    ).then((result) => {
      if (result && isPageVisibleRef.current) {
        // checking page visibility cuz when android with installed insta,
        // background page exchanges token and foreground page (the one with instagram callback) triggers photo picker
        pickImagesFlow();
      }
    });
  }, [authCode]);

  return (
    <DropdownListItem
      className={styles.root}
      onClick={onConnectInstagramClick}
      titleMessage={message}
      Icon={ConnectInstagramIcon}
      messageClassName={styles.container}
    >
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH5}
        className={styles.disclaimer}
      >
        <FormattedMessage
          id="only_instagram_photos"
          defaultMessage="Only Instagram photos will appear in your Tango profile. All other data will remain private."
        />
      </Typography>
    </DropdownListItem>
  );
};

export default memo(InstagramListItem);
