import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { FROM_FLOW } from "enums/modalDismissReason";
import { TOKEN_TYPE_INSTAGRAM } from "enums/socialNetworkTokenType";
import { ModalType } from "src/enums";
import { dismissModalWithType } from "state/actionCreators/modal";
import { autoSyncInstagramPhotos } from "state/flows/instagram";
import { updateAutoSyncFlag } from "state/flows/socialNetworkTokens";
import { ThemeContext } from "ui/Theme";
import ConfirmationView from "../ConfirmationView";
import instaTitleHorizontal from "img/ic_instagram_title_white_hor.svg?url";
import instaIcon from "img/ic_social_icon_instagram.svg?url";
import styles from "./InstagramAllowAllPhotosAccess.scss";

const InstagramAllowAllPhotosAccess = ({ dismiss }) => {
  const { dark } = useContext(ThemeContext);
  const btnClicked = useRef(false);
  const dispatch = useDispatch();
  const onAllowClick = useCallback(() => {
    btnClicked.current = true;
    dispatch(
      dismissModalWithType({
        modalType: ModalType.INSTAGRAM_CHOOSE_PHOTOS_MODAL,
        modalDismissReason: FROM_FLOW,
      })
    );
    dismiss();
    dispatch(
      updateAutoSyncFlag({ autoSync: true, tokenType: TOKEN_TYPE_INSTAGRAM })
    ).then(() => dispatch(autoSyncInstagramPhotos()));
  }, []);
  const onDisallowClick = useCallback(() => {
    btnClicked.current = true;
    dismiss();
    dispatch(
      updateAutoSyncFlag({ autoSync: false, tokenType: TOKEN_TYPE_INSTAGRAM })
    );
  }, []);
  useEffect(
    () => () => {
      if (!btnClicked.current) {
        dispatch(
          updateAutoSyncFlag({
            autoSync: false,
            tokenType: TOKEN_TYPE_INSTAGRAM,
          })
        );
      }
    },
    []
  );
  return (
    <ConfirmationView
      header={
        <div className={styles.header}>
          <img src={instaIcon} className={styles.instaIcon} />
          <img
            src={instaTitleHorizontal}
            className={!dark && styles.inverted}
          />
        </div>
      }
      onCancel={onDisallowClick}
      onConfirm={onAllowClick}
      showCloseButton
    />
  );
};

InstagramAllowAllPhotosAccess.propTypes = {
  dismiss: PropTypes.func.isRequired,
};

export default InstagramAllowAllPhotosAccess;
